<template>
	<!--订单详情-->
	<div class="oDetail" v-loading="detailLoading">
		<div class="evbtns" v-if="allowExamine">
			<div class="it_btn" v-for='(it,dex) in operatBtns' :class="it.type" :key='dex' @click.stop='openPopupByTtpe(it)' ><img :src="it.icon" class="_icon" /> {{it.name}}</div>
		</div>
		<div class="checkInfo" ></div>
		<div class="detailMain">
			<div class="top_des txNowrap">
				<span class="typelabel">{{stepStr}}</span>企业入驻信息进度
			</div>
			<div class="statusLine">
				<div class="l_it" v-for="(it,dex) in lineDatas.Arr" :key='dex' :class="{isOver:dex<=(lineDatas.val-1)}">
					<div class="s_name"  :class="{isNow:dex==(lineDatas.val)}">
						<img class="s_icon" v-if="dex<=(lineDatas.val-1)" src="@/assets/images/order/green1.png"/>
						<span class="s_round"  v-else></span>
						<span >{{it.processTypeName}}</span>	
						</div>
					<div class="d_its">
						<ul >
							<li class="itflex" v-if="i<it.showIts" v-for="(_d,i) in it.orderDynamicVOS" :key='i'>
								<div class="txNowrap">{{_d.userName}}</div>
								<div class="txNowrap">{{_d.operationName}}</div>
								<div class="cause">{{common.getTimeMmss(_d.creationDate)}}</div>
								<div class="cause" v-if='_d.explainCard'>
										驳回原因：{{_d.explainCard}}
									</div>
								
							</li>
							<li v-if="it.maxlen>2">
								<el-button v-if='it.showIts<3' type="text" class='txGray' @click='it.showIts=it.orderDynamicVOS.length'>展开  <i class="el-icon-arrow-down"></i></el-button>
								<el-button v-else type="text" class='txGray' @click='it.showIts=2'>收起  <i class="el-icon-arrow-up"></i></el-button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div>
				<orderInfo type='1' :datas='orderInfo'></orderInfo>
				<orderInfo type='2' :datas='orderInfo'></orderInfo>
				<orderInfo type='3' v-if='orderInfo.deliverInfo' :datas='orderInfo.deliverInfo'></orderInfo>
			</div>
		</div>
		<operatePopup :isOpen='exPopInfo.open' :chekIndex='chekIndex' :type='exPopInfo.type' :datas='orderInfo' @close='closeDialog' @resetDetail='initHtml'></operatePopup>
	</div>
	
</template>

<script>
	import { getOrderDetail } from "@/api/business/order/tenant/order";
	import handsRlink from "@/mixins/handsRlink.js";
	import TForm from '@/components/YTable/TForm.vue';
	import operatePopup from "./model/operatePopup.vue";
	import orderInfo from "./model/orderInfo.vue";
	export default {
		name: "OrderDetail",
		components: {TForm,orderInfo,operatePopup},
		mixins: [handsRlink],
		props: {},
		data() {
			return {
				stepStr:'状态',
				chekIndex:1,
				statusArrs:[
					//入驻状态 0待审核1待入驻2入驻中 3已入驻 4已驳回
					{label:'待审核',value:0},
					{label:'待入驻',value:1},
					{label:'入驻中',value:2},
					{label:'已入驻',value:3},
					{label:'已驳回',value:4},
				],
				siteTypes:[//场地现状
					{name:'毛坯',code:'1'},
					{name:'简装',code:'2'},
					{name:'精装',code:'3'},
					{name:'带家具',code:'4'},
					{name:'无家具',code:'5'},
				],
				detailLoading:false,
				operatBtns:[
					{name:'通过审核',val:'pass',type:'primary',icon:require('@/assets/images/order/wancheng.png')},
					{name:'驳回',val:'reject',type:'',icon:require('@/assets/images/order/b_lq.png')},
				
				],
				operatObjs:{
					//这个对象是前端与后台对应的数据   其中val是前端定义 关联对应打开的弹窗
					1:{name:'领取',val:'receive',type:'',icon:require('@/assets/images/order/b_lq.png')},
					2:{name:'转派',val:'reassignment',type:'',icon:require('@/assets/images/order/b_zp.png')},
					3:{name:'协助',val:'assist',type:'',icon:require('@/assets/images/order/b_xz.png')},
					4:{name:'领料',val:'drawMatter',type:'',icon:require('@/assets/images/order/b_ll.png')},
					5:{name:'延时',val:'delayed',type:'',icon:require('@/assets/images/order/b_ys.png')},
					6:{name:'关单',val:'closeOrder',type:'',icon:require('@/assets/images/order/b_gd.png')},
					7:{name:'完成',val:'finishOrder',type:'primary',icon:require('@/assets/images/order/wancheng.png')},
					8:{name:'去执行',val:'toExecute',type:'',icon:require('@/assets/images/order/b_zx.png')},
					9:{name:'去签到',val:'toSign',type:'',icon:require('@/assets/images/order/b_qd.png')},
				},
				orderId:'',
				auditType:'',
				orderInfo:{
					rentStatus:-1,//状态
					contentDesc:'',
					enterpriseRentExamineVOS:[],
				},//记录存储工单详情信息
				isExecutingClose:false,//判断是否执行中进行关单
				alarmVoDetail:'',
				examineArrs:[],
				exPopInfo:{
					open:false,
					type:'pass',
				},
				nowUserId:'',
				lineDatas:{
					val:1,
					Arr:[
		        {
		            "processType": 1,
		            "processTypeName": "企业入驻",
		            'showIts':2,
						    'maxlen':3,
		            "orderDynamicVOS": []
		        },
		        {
		            "processType": 2,
		            'showIts':2,
		            "processTypeName": "招商部审核",
		            "orderDynamicVOS": []
		        },
		        {
		            "processType": 3,
		            'showIts':2,
		            "processTypeName": "财务部审核",
		            "orderDynamicVOS": []
		        },
		        {
		            "processType": 4,
		            'showIts':2,
		            "processTypeName": "客服部审核",
		            "orderDynamicVOS": []
		        },
		        {
		            "processType": 5,
		            'showIts':2,
		            "processTypeName": "入驻场地交付",
		            "orderDynamicVOS": []
		        },
		        {
		            "processType": 6,
		            'showIts':2,
		            "processTypeName": "完成入驻",
		            "orderDynamicVOS": []
		        }
		        
					],//item={name:'报单',detail:[]}
				},
				
			};
		},
		computed: {
		    orderEditOrSumit() {
		      	return this.$store.state.app.orderUpdate;
		    },
		    allowExamine(){
					//是否有审核权限
					let num=this.lineDatas.val;
					let isAllow=false;
					if(this.orderInfo.rentStatus===0){
						const Arrs=this.orderInfo.enterpriseRentExamineVOS;
//						console.log(5555555555,Arrs,num)
						if(Arrs.length>0){
							let nowCkId=Arrs[num-1].userId;//取出当前审核人的id  匹配是否是登入帐号的userid 是的话则有审核按钮
//							console.log('nowCkId',nowCkId,this.nowUserId)
							isAllow=nowCkId==this.nowUserId;
						}
					}
					return isAllow;
				
					
				},
	  	},
		watch:{
			orderEditOrSumit: {
		      	deep: true,
		      	handler(v, n) {
		      		let pupType=this.$store.state.app.orderAddEdit.type;
//		      		console.log('-------',pupType)
		      		if(pupType=='againAdd'){
		      			this.$router.go(-1);
		      		}else{
		      			this.initHtml();
		      		}
		        	
		      	},
		    },
			
		},
		created() {
			this.nowUserId=localStorage.getItem('userId');
			const {orderId,auditType}=this.$route.query;
//			console.log('orderId',orderId)
			if(orderId){
				this.orderId=orderId;
			}
			if(auditType){
				this.auditType=auditType;
			}
			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
//			this.$store.dispatch('getAllDevices');//获取部门人员数据
			this.initHtml();
		},
		mounted() {},
		methods: {
			
			initHtml(){
				this.getDetailById();
			},
			initOperatBtns(Arrs){
				this.operatBtns=[];
				if(!Arrs) return;
				//BUTTON_RECEIVE("领取",1),BUTTON_TRANSFER("转派",2),BUTTON_ASSIST("协助",3),BUTTON_MATERIEL("领料",4),
				//BUTTON_DELAYED("延时",5),BUTTON_CLOSE("关单",6),BUTTON_COMPLETE("完成",7),BUTTON_EXECUTE("去执行",8),
				//BUTTON_SIGN("去签到",9)
				//后台过来的数据  [{type: 3, typeName: "协助", show: true}] 需要与前端进行匹配
				let fArr=[];
				Arrs.map(it=>{
					if(it.show&&it.type){
						let itBtn=this.operatObjs[Number(it.type)];
						if(itBtn) fArr.push(itBtn);
					}
				})
//				for(let keys in this.operatObjs){
//					fArr.push(this.operatObjs[keys])
//				}
				this.operatBtns=fArr;
				//最终得到前端需要的数据   其中val是前端需要的数据
//				operatBtns=[
//					{name:'完成',val:'finishOrder',icon:'xxx',type:'primary'},
//				];
			},
			
			async	getDetailById(){
				//详情
		      	this.detailLoading = true;
		      	let params={
		      		rentOrderId:this.orderId,
		      		isloading:false,
		      	}
		     	let bkData = await this.ApiHttp('/organization/enterprise/rent/selectDetail',params);
		     	this.detailLoading = false;
		      	if(bkData){
		      		this.chekIndex=bkData.progressBar-1;//该字段后台需要的，对应审核顺序
		      		this.lineDatas.val=bkData.progressBar;
		      		if(bkData.progressBar>0&&bkData.progressBar<6) this.lineDatas.val--;
		      		let res=bkData.enterpriseRentOrderVO;
		      		const  {rentDynamic}=bkData;
		      		if(rentDynamic){
		      			for(let i=0;i<rentDynamic.length;i++){
		      				this.lineDatas.Arr[i].maxlen=rentDynamic[i].length;
		      				this.lineDatas.Arr[i].orderDynamicVOS=rentDynamic[i];
		      			}
		      		}
		      		if(bkData.enterpriseRentDeliverOrderVO){
		      			let dInfo=bkData.enterpriseRentDeliverOrderVO;
		      			dInfo.rentArea=res.rentArea;
		      			dInfo.positionName=res.positionName;
		      			dInfo.actualDeliverDateStr=this.common.getTimeMmss(dInfo.actualDeliverDate,'day');
		      			if(dInfo.siteStatus) dInfo.siteStatusStr=this.siteTypes[+dInfo.siteStatus-1].name;
		      			res.deliverInfo=bkData.enterpriseRentDeliverOrderVO;
		      		}
		      		this.stepStr=this.statusArrs[+res.rentStatus].label;
//	      			console.log('222222',res)
	      			res.rentDormitoryStr=res.rentDormitory=='Y'?'是':'否';//是否租用宿舍
	      			res.rentAirconditionStr=res.rentAircondition=='Y'?'是':'否';//是否租用空调
	      			res.rentDateStr=this.common.getTimeMmss(res.rentDate,'day');
	      			res.leaseContractTime=this.common.getTimeMmss(res.leaseContractStartDate,'day')+' 至  '+this.common.getTimeMmss(res.leaseContractEndDate,'day');
		      		this.orderInfo = {...res};
		      	}
			},
			
			async openPopupByTtpe(data){
				let type=data.val;
				if(this.lineDatas.val==3){
					//当如果是客服部审核的话。
					type='kfExamine'
				}
				
				this.exPopInfo={
					type:type,
					open:true,
				}
			},
			async receiveOrExecute(type='receive'){
				//领取工单
				let params={
					orderId: this.orderInfo.orderId
				}
				let toUrl='/receiveOrder';
				if(type=='toExecute'){
					toUrl='/implementOrder'
				}
				let res=await this.ApiHttp('/order/tenant/orderFill'+toUrl,params,'PUT');
				if(res){
					this.initHtml();
				}
			},
			closeDialog(){
				this.exPopInfo.open=false;
			},
			toTable(){
				this.$router.go(-1);
			},
			
			
		},
	};
</script>
<style type="text/css" lang="scss" scoped>
	.oDetail {
		height: calc(100vh - 60px);
		overflow-y: auto;
		box-sizing: border-box;
		padding: 0 24px;
		.evbtns{
			padding-top: 16px;
			.it_btn{
				display: inline-block;
				width: 96px;
				line-height: 40px;
				font-size: 14px;
				color: #4E5969;
				background: white;
				border-radius: 3px;
				text-align: center;
				border: 1px solid #E4E7ED;
				margin-right: 12px;
				cursor: pointer;
			}
			.it_btn.primary{
				color: white;
				background: #1A4CEC;
			}
			.it_btn:hover{
				background: #ecf5ff;
			}
			._icon{
			    vertical-align: sub;
			}
		}
		.checkInfo{
			margin-top: 16px;
		}
		.detailMain{
			background: #FFFFFF;
			border-radius: 4px;
			padding: 8px 32px;
			.top_des{
				font-size: 16px;
				font-weight: bold;
				color: #2F3941;
				padding: 24px 0;
				.typelabel{
					display: inline-block;
					padding: 0 12px;
					height: 27px;
					font-size: 14px;
					font-weight: bold;
					color: #1A4CEC;
					line-height: 27px;
					background: #F4F6FE;
					border-radius: 2px;
					text-align: center;
					margin-right: 16px;
				}
				
			}
			.statusLine{
				display: flex;
				.l_it{
					flex: 1;
					border-top: 2px solid #E0E0E0;
					padding: 15px 0;
					.s_name{
						color: #909399;
						span{
							font-weight: bold;
							font-size: 14px;
						}
						.s_icon,.s_round{
							margin-right: 8px;
						}
						.s_round{
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							line-height: 14px;
							border: 1px dashed #909399;
							font-size: 12px;
							text-align: center;
							vertical-align: top;
						}
					}
					.isNow.s_name{
						
						color: #1A4CEC;
						.s_round{
							border: 1px dashed #1A4CEC;
						}
					}
					.d_its{
						font-size: 13px;
						margin-top: 8px;
						color: #909399;
						line-height: 24px;
						.txGray{
							padding: 0;
							color: #4E5969;
						}
						.itflex{
							.txNowrap{
								display: inline-block;
								vertical-align: middle;
								position: relative;
							}
							.txNowrap:nth-child(1){
								width: 32%;
							}
							.txNowrap:nth-child(2){
								padding:0 8px;
								width: 68%;
							}
							/*.txNowrap:nth-child(3){
								width: 48%;
							}*/
							.cause{
									font-size: 12px;
									color: #909399;
									line-height: 16px;
								}
						}
					}
				}
				.l_it.isOver{
					border-top: 2px solid #1A4CEC;
					.s_name{
						color:#000000;
						
						/*.s_round{
							border: 1px solid #55D057;
						}
						.s_round::after{
							content:'✓'
						}			*/
					}
				}
			
			}
		}
	}
</style>
<style type="text/css" lang="scss">

</style>