<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="popupTitle" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="428px" :before-close="closeMypup">
			<div v-if="pupIsOpen" :key='type'>
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="yanshiTime" slot-scope="scope">
						
					</template>
				</TForm>
				<div class="btmBtn" >
	    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
	    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'>
	    			<span v-if="type=='pass'">确认通过</span>
	    			<span v-else>确定</span>
	    		</el-button>
        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			datas:{
				type:Object,
				default:()=>{
					return {
						rentOrderId:'',
					}
				}
			},
			chekIndex:{
				type: Number,
				default: 1,
			},
			type:{
				type: String,
				default: 'pass',
			},
		},
		components: {TForm},
		computed: {
		    pupIsOpen() {
		      	return this.$attrs.isOpen;
		    },
	  	},
		watch:{
			type:{
				deep: true,
				handler(n,o){
					this.resetTFrom();
				},
			},
			pupIsOpen:{
				deep: true,
				handler(n,o){
					if(n){
//						console.log('弹窗展开了................')
						
					}else{
						this.resetTFrom();
					}
				},
			},
		},
		data() {
			return {
				editPositionList: [],//空间位置数据
				htmlDatas:{},
  			isHttping:false,
  			formlist:[],
  			popupTitle:'转派申请',
  			materielArrs:[],//物料
  			oConfig:{},//工单配置信息
  			applyTypes:{
  				//不同类型申请的内容
  				pass:{ 
  					name:'提示',
  					httpUrl:'/organization/enterprise/rent/examine/auditRentOrder',
  					method:'POST',
  					formIts:[
  						{name: '通过审核',keys: 'tips',value: '是否通过审核申请',type: 'text',labelHide:true},
  					]
  				},
  				reject:{
  					name:'驳回',
  					httpUrl:'/organization/enterprise/rent/examine/auditRentOrder',
  					method:'POST',
  					formIts:[
  						{name: '驳回原因',keys: 'examineFailReason',value: '',type: 'input',flex:24,isMust:true},
  					]
  				},
  				kfExamine:{
  					name:'通过申请',
  					tips:'这个是客服审核',
  					httpUrl:'/organization/enterprise/rent/examine/auditRentOrder',
  					method:'POST',
  					formIts:[
  						{name: '请指定场地交付执行人',keys: 'siteDeliveryUser',value: '',type: 'employe',isMust:true,flex:13},
  					]
  				},
  			},
			}
		},
		
		created() {
			this.resetTFrom();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			async resetTFrom(){
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
				this.htmlDatas={};
//				let pData=JSON.parse(JSON.stringify(this.applyTypes[this.type]));
				let pData=this.applyTypes[this.type];
//				console.log('pData-resetTFrom',pData,this.type)
				this.formlist=pData.formIts;
				this.popupTitle=pData.name;
  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
  		},
			closeMypup(){
				this.$emit('close')
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let keyArr=Object.keys(this.applyTypes);
				let index=keyArr.indexOf(this.type)+1;
				let params={
					...this.htmlDatas,
					rentOrderId:this.datas.rentOrderId,
					examineSequence:this.chekIndex,
				}
//				console.log('params',params)
				let urls=this.applyTypes[this.type].httpUrl;
				let res = await this.ApiHttp(urls,params,'post');
				if(res){
					this.$message({type:'success',message:'操作成功'});
					this.closeMypup();
					this.$emit('resetDetail');//刷新详情
				}
				
				
//				let urlType='insert'
//				if(this.dataId){
//					urlType='update';//编辑
//				}
//				params.enterpriseTag=params.enterpriseTag.join(',');
//				let res = await this.ApiHttp('/organization/enterprise/'+urlType,params,'post');
//				if(res){
//					this.$refs.htmlFrom.resetFields();
//					this.$emit('close','init')
//				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.inpNum{
	padding-left:8px;
}
.mtWarp{
	padding-top: 18px;
}
.itMtrow{
	padding:8px 0;
}
.tx_tlue{
	color:#1A4CEC;
}
.signWarp{
	height: 168px;
	padding-top: 24px;
}
</style>